import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { useFormik } from "formik";
import { ChangeEvent, Fragment, useRef, useState } from "react";
import { Button } from "src/components/Button";
import {
  Error,
  FormInput,
  PasswordInput,
  SelectInput,
} from "src/components/inputs";
import { Upload } from "src/components/Upload";
import { FormType, UpdateFormType } from "src/helpers/alias";
import { states } from "src/helpers/data";
import { convert2base64, generatePassword } from "src/helpers/helperFunction";
import { AddbranchValidation } from "src/helpers/YupValidation";
import { IMAGE_COMPRESS_OPTIONS } from "src/helpers/Constant";
import imageCompression from "browser-image-compression";

const GoogleLocationInput = ({
  getLat,
  getLong,
  getAddress,
}: {
  getLat: (arg: number) => void;
  getLong: (arg: number) => void;
  getAddress: (arg: string) => void;
}) => {
  const originRef = useRef();
  const [result, setResult] = useState<any>(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCDfI1GOcaZ2W3xQZyWwN_d2ZUzMufGSS4",
    libraries: ["places", "geometry"],
  });
  const onLoad = (autoComplete: any) => {
    setResult(autoComplete);

    const nigeriaBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(4.21, 2.68),
      new google.maps.LatLng(13.9, 14.67)
    );
    autoComplete.setBounds(nigeriaBounds);
    autoComplete.setOptions({ strictBounds: true });
  };
  const onLocationSelected = () => {
    const place = result?.getPlace();

    getAddress(place.formatted_address as any);

    getLat(place.geometry.location.lat());
    getLong(place.geometry.location.lng());
  };

  return (
    <div>
      {isLoaded ? (
        <Autocomplete onLoad={onLoad} onPlaceChanged={onLocationSelected}>
          <>
            <label className="block mb-[6px] text-black text-start font-normal text-[14px]  my-2">
              Enter Address
            </label>
            <input
              className="h-[54px] rounded-lg w-full border border-gray-300 px-4 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
              ref={originRef as any}
              name="origin"
            />
          </>
        </Autocomplete>
      ) : null}
    </div>
  );
};

export const AddNewBranch = (props: {
  makeApiRequest: (args: FormType) => void;
  apiResult: { isLoading: boolean };
  initalValue?: { [index: string]: string } | any;
}) => {
  const [step, setStep] = useState<number>(0);

  const Formik = useFormik<FormType>({
    initialValues: { ...props.initalValue },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AddbranchValidation[step],

    onSubmit: (values) => {
      if (step === 2) {
        props.makeApiRequest(values);
        // addNewBranchFunct(values);
      } else {
        setStep((prev) => prev + 1);
      }
    },
  });

  const getLong = (value: number) => {
    Formik.setFieldValue("location.longitude", String(value));
  };
  const getLat = (value: number) => {
    Formik.setFieldValue("location.latitude", String(value));
  };

  const getAddress = (value: string) => {
    Formik.setFieldValue("location.address", value);
  };

  // useEffect(() => {
  // 	if (!props.initalValue) return;

  // 	// Formik.setValues({ ...props.initalValue });
  // }, []);

  const styles =
    "h-[38px] py-6 rounded-lg w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
  const labelStyles =
    "block mb-[6px] text-black text-start font-normal text-[14px] text-black my-2";

  const FormData = [
    {
      id: "name",
      name: "Branch name",
      type: "text",
      styles: `${styles} ${
        Formik.errors.name && Formik.touched.name
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.name,
      onBlur: Formik.handleBlur,
      disabled: props.apiResult?.isLoading,
      error: Formik.errors.name,
      touched: Formik.touched.name,
    },
    {
      id: "phoneNumber",
      name: "Branch contact info",
      type: "text",
      styles: `${styles} ${
        Formik.errors.phoneNumber && Formik.touched.phoneNumber
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.phoneNumber,
      onBlur: Formik.handleBlur,
      disabled: props.apiResult?.isLoading,
      error: Formik.errors.phoneNumber,
      touched: Formik.touched.phoneNumber,
    },
    {
      id: "location.lga",
      name: "Branch LGA",
      type: "text",
      styles: `${styles} ${
        Formik.errors.location?.lga && Formik.touched.location?.lga
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.location.lga,
      onBlur: Formik.handleBlur,
      disabled: props.apiResult.isLoading,
      error: Formik.errors.location?.lga,
      touched: Formik.touched.location?.lga,
    },
    {
      id: "pumpCount",
      name: "Branch pumps",
      type: "number",
      styles: `${styles} ${
        Formik.errors.pumpCount && Formik.touched.pumpCount
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.pumpCount,
      onBlur: Formik.handleBlur,

      disabled: props.apiResult?.isLoading,
      error: Formik.errors.pumpCount,
      touched: Formik.touched.pumpCount,
    },
    {
      id: "branchManager.firstName",
      name: "Branch manager first name",
      type: "text",
      styles: `${styles} ${
        Formik.errors.branchManager?.firstName &&
        Formik.touched.branchManager?.firstName
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.branchManager.firstName,
      onBlur: Formik.handleBlur,

      disabled: props.apiResult?.isLoading,
      error: Formik.errors.branchManager?.firstName,
      touched: Formik.touched.branchManager?.firstName,
    },

    {
      id: "branchManager.lastName",
      name: "Branch manager last name",
      type: "text",
      styles: `${styles} ${
        Formik.errors?.branchManager?.lastName &&
        Formik.touched?.branchManager?.lastName
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values?.branchManager?.lastName,
      onBlur: Formik.handleBlur,
      disabled: props.apiResult?.isLoading,
      error: Formik.errors?.branchManager?.lastName,
      touched: Formik.touched?.branchManager?.lastName,
    },
    {
      id: "branchManager.email",
      name: "Branch manager email",
      type: "email",
      styles: `${styles} ${
        Formik.errors?.branchManager?.email &&
        Formik.touched.branchManager?.email
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values?.branchManager?.email,
      onBlur: Formik.handleBlur,
      disabled: props.apiResult?.isLoading,
      error: Formik.errors.branchManager?.email,
      touched: Formik.touched.branchManager?.email,
    },
    {
      id: "branchManager.phoneNumber",
      name: "Branch manager contact info",
      type: "text",
      styles: `${styles} ${
        Formik.errors.branchManager?.phoneNumber &&
        Formik.touched.branchManager?.phoneNumber
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.branchManager?.phoneNumber,
      onBlur: Formik.handleBlur,
      disabled: props.apiResult?.isLoading,
      error: Formik.errors.branchManager?.phoneNumber,
      touched: Formik.touched.branchManager?.phoneNumber,
    },
  ];

  async function uploadAvatar(e: { [index: string]: string | any }) {
    const compressedFile = await imageCompression(
      e.target.files[0],
      IMAGE_COMPRESS_OPTIONS
    );
    const file = await convert2base64(compressedFile);
    Formik.setFieldValue("coverImage", file);
  }

  return (
    <form
      onSubmit={Formik.handleSubmit}
      className="w-full flex flex-col justify-center items-center px-4 h-full"
    >
      {step === 0 ? (
        <div className="grid grid-cols-1 w-full gap-x-2 content-center">
          {FormData.slice(0, 4).map((dt, i) => (
            <Fragment key={dt.id}>
              <FormInput
                id={dt.id}
                name={dt.name}
                type={dt.type}
                styles={dt.styles}
                labelStyles={dt.labelStyles}
                onChange={dt.onChange}
                value={dt.value}
                onBlur={dt.onBlur}
                disabled={dt.disabled}
                error={dt.error}
                touched={dt.touched}
              />
              {i === 2 ? (
                <>
                  <GoogleLocationInput
                    getLat={getLat}
                    getLong={getLong}
                    getAddress={getAddress}
                  />
                  {(Formik.errors.location?.longitude ||
                    Formik.errors.location?.latitude) && (
                    <>
                      <Error error={"Please select a valid location"} />
                    </>
                  )}
                </>
              ) : null}
              {i === 2 ? (
                <SelectInput
                  id="location.state"
                  data={states}
                  labelStyles={labelStyles}
                  name="Select state"
                  onChange={(e) =>
                    Formik.setFieldValue("location.state", e.target.value)
                  }
                  value={Formik.values.location.state}
                />
              ) : null}
            </Fragment>
          ))}
        </div>
      ) : null}
      {step === 1 ? (
        <div className="grid grid-cols-1 w-full gap-x-2 content-center">
          {FormData.slice(-4).map((dt, i) => (
            <FormInput
              id={dt.id}
              name={dt.name}
              type={dt.type}
              styles={dt.styles}
              labelStyles={dt.labelStyles}
              onChange={dt.onChange}
              value={dt.value}
              onBlur={dt.onBlur}
              disabled={dt.disabled}
              error={dt.error}
              touched={dt.touched}
            />
          ))}

          <PasswordInput
            width="w-full"
            id="password"
            name={"Password"}
            type={"text"}
            styles={` ${
              Formik.errors.branchManager?.password &&
              Formik.touched.branchManager?.password
                ? "border-red-500"
                : "border-gray-300"
            }`}
            labelStyles={labelStyles}
            onChange={(e) => {
              Formik.setFieldValue("branchManager.password", e.target.value);
            }}
            value={Formik.values.branchManager?.password}
            onBlur={Formik.handleBlur}
            disabled={props.apiResult.isLoading}
            // error={Formik.errors.password}
            // touched={Formik.touched.password}
          />
          <Button
            text="Generate password"
            disabled={props.apiResult.isLoading}
            className="h-[41px] mt-6 font-bold bg-white border border-[#002E66] rounded-[38px] w-full hover: text-[#002E66]"
            type="button"
            onClick={() => {
              Formik.setFieldValue(
                "branchManager.password",
                generatePassword()
              );
            }}
          />
        </div>
      ) : null}
      {step === 2 ? (
        <div className="w-full h-full mt-4">
          {Formik.values?.coverImage ? (
            <div className="w-full flex items-center overflow-x-auto py-2 h-40">
              <img
                src={Formik.values?.coverImage || ""}
                width={200}
                height={200}
                className="h-24 object-contain w-full "
                alt="branch logo"
              />
            </div>
          ) : null}
          {(Formik.errors.coverImage || Formik.errors.coverImage) && (
            <>
              <Error error={"Please select a cover image"} />
            </>
          )}
          <Upload
            text="Upload profile image"
            name="coverImage"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              uploadAvatar(e);
            }}
          />
        </div>
      ) : null}

      <div className="w-full">
        {step > 0 && step < 2 ? (
          <Button
            text="Back"
            disabled={props.apiResult.isLoading || false}
            showModal={props.apiResult.isLoading}
            className="h-[41px] mt-6 font-bold bg-white border border-[#002E66] rounded-lg w-full hover: text-[#002E66]"
            type="button"
            onClick={() => setStep((prev) => prev - 1)}
          />
        ) : null}

        <Button
          text={step < 2 ? "Next" : "Add New Branch"}
          disabled={props.apiResult?.isLoading}
          showModal={props.apiResult?.isLoading}
          className="h-[41px] mt-6 font-bold text-white rounded-lg w-full hover: bg-[#002E66]"
          type="submit"
        />
      </div>
    </form>
  );
};

export const UpdateManagersDetails = (props: any) => {
  const initValue = props.initalValue;

  const Formik = useFormik<any>({
    initialValues: {
      ...initValue,
    },
    validateOnBlur: true,
    validateOnChange: true,
    // validationSchema: AddbranchValidation[step],

    onSubmit: (values) => {
      props.makeApiRequest(values);
    },
  });
  const styles =
    "h-[38px] py-6 rounded-[38px] w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
  const labelStyles =
    "block mb-[6px] text-black text-start font-normal text-[14px] text-black ml-5 my-6";

  const FormData = [
    {
      id: "firstName",
      name: "Branch manager first name",
      type: "text",
      styles: `${styles} ${
        Formik.errors?.firstName && Formik.touched?.firstName
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.firstName,
      onBlur: Formik.handleBlur,

      // disabled: addNewBranchResult?.isLoading,
      // error: Formik.errors.branchManager?.firstName,
      // touched: Formik.touched.branchManager?.firstName,
    },
    {
      id: "lastName",
      name: "Branch manager last name",
      type: "text",
      styles: `${styles} ${
        Formik.errors?.lastName && Formik.touched?.lastName
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values?.lastName,
      onBlur: Formik.handleBlur,
      // disabled: addNewBranchResult?.isLoading,
      // error: Formik.errors?.branchManager?.lastName,
      // touched: Formik.touched?.branchManager?.lastName,
    },
    // {
    //   id: "email",
    //   name: "Branch manager email",
    //   type: "email",
    //   styles: `${styles} ${
    //     Formik.errors?.email && Formik.touched?.email
    //       ? "border-red-500"
    //       : "border-gray-300"
    //   }`,
    //   labelStyles: labelStyles,
    //   onChange: Formik.handleChange,
    //   value: Formik.values?.email,
    //   onBlur: Formik.handleBlur,
    //   // disabled: addNewBranchResult?.isLoading,
    //   // error: Formik.errors.branchManager?.email,
    //   // touched: Formik.touched.branchManager?.email,
    // },
  ];

  return (
    <form
      onSubmit={Formik.handleSubmit}
      className="w-full flex flex-col justify-center items-center px-4 h-full"
    >
      <div className="grid grid-cols-1 w-full gap-x-2 content-center">
        {FormData.slice(0, 6).map((dt, i) => (
          <FormInput
            id={dt.id}
            name={dt.name}
            type={dt.type}
            styles={dt.styles}
            labelStyles={dt.labelStyles}
            onChange={dt.onChange}
            value={dt.value}
            onBlur={dt.onBlur}
            // disabled={dt.disabled}
            // error={dt.error}
            // touched={dt.touched}
          />
        ))}
      </div>

      <div className="w-full">
        <Button
          text="Update Branch Details"
          disabled={props.apiResult?.isLoading}
          showModal={props.apiResult?.isLoading}
          className="h-[41px] mt-6 font-bold text-white rounded-lg w-full hover: bg-[#002E66]"
          type="submit"
        />
      </div>
    </form>
  );
};
